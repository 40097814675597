import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { PostDiligenceDialogComponent } from '../post-diligence-dialog/post-diligence-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service'
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies-grid',
  templateUrl: './companies-grid.component.html',
  styleUrls: ['./companies-grid.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatButtonModule, MatIconModule, FormsModule, ClipboardModule, MatPaginatorModule],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CompaniesGridComponent {

  router = inject(Router);

  @Input()
  companies = [];

  @Input()
  individuals = [];

  @Input()
  diligences: any;

  @Input()
  isAdmin: boolean | null = false;

  @Input()
  email!: string;

  @Input()
  page: number = 0;

  @Input()
  pageSize: number = 10;

  @Output()
  requestDiligence = new EventEmitter<any>();

  @Output()
  requestDiligenceById = new EventEmitter<any>();

  @Output()
  getCompanies = new EventEmitter<any>();

  @Output()
  getIndividuals = new EventEmitter<any>();

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  columnsToDisplay = ['name', 'regNo', 'number', 'address', 'locationType', 'companyStatus' ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any;

  columnsToDisplayIndividuals = ['firstName', 'lastName', 'company', 'address', 'country', 'number'];
  columnsToDisplayWithExpandIndividuals = [...this.columnsToDisplayIndividuals, 'expand'];
  expandedElementIndividuals: any;


  getDiligence(company: any, type: string) {
    if (this.individuals?.length) {
      this.requestDiligence.emit({
        target: {
          fullName: company.firstName + ' ' + company.lastName,
          country: company.country,
          address: company.address.simpleValue
        },
        ...company,
        country: company.country,
        code: company.peopleId,
        name: company.firstName + ' ' + company.lastName,
        type: type
      });
    } else if (this.companies?.length) {
      this.requestDiligence.emit({
        target: {
          companyName: company.name,
          country: company.country,
          address: company.address?.simpleValue,
        },
        ...company,
        country: company.country,
        code: company.safeNo,
        type: type
      });
    }
  }

  redirectToSearch(): void {
    this.router.navigate(['/search']);
  }

  getDiligenceById(id: string) {
    this.requestDiligenceById.emit({
      jobId: id
    });
  }
}
