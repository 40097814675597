import { Component } from '@angular/core';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { DatePipe, CommonModule } from '@angular/common';

@Component({
    selector: 'app-pdf-header',
    imports: [ReportFieldDirective, DatePipe, CommonModule],
    standalone: true,
    template: `
    <div class="pdf-header">
        <div class="logo-container">
            <img src="../../../assets/TevunaLogo.png" alt="Tevuna Logo" class="logo"/>
            <span class="tagline">Discover the Impossible</span>
        </div>
    </div>
    `,
    styles: [`
        .pdf-header {
            width: 100%;
            padding: 20px 0;
            text-align: center;
        }
        .logo-container {
            display: inline-block;
            position: relative;
            width: 224px; /* 56 * 4 to match w-56 */
        }
        .logo {
            width: 100%;
            height: auto;
        }
        .tagline {
            position: absolute;
            left: 16px; /* 4 * 4 to match left-4 */
            bottom: 4px;
            color: #075985; /* sky-800 */
            font-size: 12px;
            font-family: sans-serif;
        }
        @media print {
            .pdf-header {
                position: running(pdf-header);
            }
        }
    `]
})
export class PdfHeaderComponent {
}