import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { MarkdownComponent, MarkdownService, MarkdownModule } from 'ngx-markdown';


@Component({
  selector: 'app-sanctions',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    MarkdownComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full border-1 border-grey px-2">
        <div  *ngIf="data.companies.length > 0">
        <div class="text-xl font-bold mb-2 bg-bgColor p-1">Companies</div>
        <div *ngFor="let company of data.companies">
            <h4>{{ company.name }}</h4>
            <markdown [data]="convertToMarkdown(company.summary)"></markdown>
        </div>
        </div>
        <div *ngIf="data.individuals.length > 0">
        <div class="text-xl font-bold mb-2 bg-bgColor p-1">Individuals</div>
        <div *ngFor="let individual of data.individuals">
            <h4>{{ individual.name }}</h4>
            <markdown [data]="convertToMarkdown(individual.summary)"></markdown>
        </div>
        </div>
      </div>
    </div>
  `
})
export class SanctionsComponent {
  @Input() printMode: boolean = false;
  @Input() data: any;

  convertToMarkdown(text: string): string {
    return text.split('\n').map(line => {
      if (line.startsWith('**') && line.endsWith('**')) {
        return '## ' + line.replace(/\*\*/g, '');
      }
      return line;
    }).join('\n\n');
  }
}