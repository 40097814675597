<div class="flex justify-center w-screen flex-1">
    <div *ngIf="(pdfView$ | async) && !(printView$ | async)" class="fixed inset-0 bg-gray-100 flex justify-center items-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-xl">
            <p class="text-lg font-semibold text-gray-800">Generating PDF...</p>
        </div>
    </div>

    <div id="content-to-print" class="min-h-full container flex-grow overflow-y-auto">
        <app-navbar *ngIf="!(pdfView$ | async)"></app-navbar>

        <div class="flex justify-center pt-5">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>

        <div *ngIf="error">
            <p class="text-xl font-semibold text-red-600 w-full text-center">{{error}}</p>
        </div>

        <div *ngIf="(pdfView$ | async)">
            <app-pdf-header></app-pdf-header>
        </div>
        <ng-container
            *ngIf="(diligence?.creditsafeData && originalDiligence.phase === 6) || (originalDiligence && isAdmin$ | async)">
            <div class="flex justify-between px-auto text-center align-center items-center p-3 w-full bg-bgColor shadow">
                <div *ngIf="diligence?.report">
                    <div class="w-full text-center font-semibold text-[20px] p-2">{{packageName}} Report</div>
                </div>
                <div class="space-x-2">
                    <button *ngIf="!(pdfView$ | async)" mat-raised-button color="secondary" (click)="printPDF()">Print
                        <mat-icon>print</mat-icon>
                    </button>
                    <button *ngIf="!(pdfView$ | async)" mat-raised-button color="secondary" (click)="generatePDF()">Download
                        <mat-icon>download</mat-icon>
                    </button>
                </div>
            </div>

            <!-- tevuna report -->
            <div class="diligenceContainer">
                <div class="flex justify-center items-center flex-col w-full">
                    <div class="flex flex-col items-center" *reportField="'tevunaReport.repScore'; let repScore">
                        <h1>V score</h1>

                        <p class="font-bold text-xl text-red">{{repScore}}</p>
                    </div>
                    <p *reportField="'tevunaReport.repSumm'; let repSumm"
                        class="text-justify p-3 bg-bgColor whitespace-pre-line">{{repSumm}}</p>
                </div>
            </div>


            <div *ngIf="transformedData.directorDetails.name">
                <app-director-details />
            </div>

            <ng-container *ngIf="((sections$ | async) || []).length > 0">

                <ng-container *ngIf="!(pdfView$ | async)">
                    <mat-tab-group>
                        <mat-tab *ngFor="let section of visibleSections$ | async" [label]="section.title">
                            <ng-template matTabContent>
                                <ng-container
                                    *ngComponentOutlet="section.component; inputs: section.data"></ng-container>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="(isAdmin$ | async)" label="Admin">
                            <ng-template matTabContent>
                                <app-modify-diligence [diligence]="diligence"
                                    (updateDiligence)="updateDiligence()"></app-modify-diligence>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>

                <ng-container *ngIf="(pdfView$ | async)">
                    <ng-container *ngFor="let section of (printableSections$ | async)">
                        <div class="pdf-section">
                            <ng-container *ngComponentOutlet="section.component; inputs: section.data"></ng-container>
                        </div>
                    </ng-container>
                    <app-disclaimer></app-disclaimer>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="resize-handle" *ngIf="showInspect$ | async" (mousedown)="startResize($event)"></div>
    <div class="h-screen overflow-scroll">
        <button *ngIf="(isAdmin$ | async)" mat-button class="mb-auto absolute" (click)="toggleInspect()">Inspect >
        </button>
        <div *ngIf="showInspect$ | async">
            <div *ngIf="(isAdmin$ | async)" class="w-1/4 flex flex-col" [style.width.px]="inspectorWidth">
                <div class="bg-gray-100 p-2">Admin Inspector</div>
                <div class="flex-grow overflow-y-scroll">
                    <ngx-json-viewer [json]="diligence" [expanded]="false"></ngx-json-viewer>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="!(pdfView$ | async)"></app-footer>